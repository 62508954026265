<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="Buy Licenses" class="pb-5" /> <br />
      <b-alert v-if="message != ''" variant="danger" disimissable show="5"
        >Error creating connector: {{ message }}</b-alert
      >
      <br />
      <div class="bar row">
        <div class="col-2">
          <p class="tabTitle">Products</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Name</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Description</p>
        </div>
        <div class="col-1">
          <p class="tabTitle">Unit Price</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Add/Remove</p>
        </div>
        <div class="col-2">
          <p class="tabTitle"></p>
        </div>
      </div>

      <div v-for="license in LicenseItems" :key="license.id" class="bar1 row">
        <!--Planner licenses -->
        <div class="col-2" v-if="license.id == 1">
          <img :src="getLogo(0)" class="icon" />
          <img :src="getLogo(1)" class="icon" />
          <img :src="getLogo(2)" class="icon" />
          <img :src="getLogo(4)" class="icon" />
          <img :src="getLogo(12)" class="icon" />
        </div>
        <!--Teams Chats licenses -->
        <div class="col-2" v-if="license.id == 2">
          <img :src="getLogo(11)" class="icon" />
          <img :src="getLogo(5)" class="icon" />
          <img :src="getLogo(9)" class="icon" />
        </div>
        <!--Teams Licenses -->
        <div class="col-2" v-if="license.id == 3">
          <img :src="getLogo(9)" class="icon" />
          <img :src="getLogo(5)" class="icon" />
          <img :src="getLogo(13)" class="icon" />
        </div>
        <!--Teams Licenses -->
        <div class="col-2" v-if="license.id == 4">
          <img :src="getLogo(0)" class="icon" />
          <img :src="getLogo(1)" class="icon" />
        </div>
        <!--Support -->
        <div class="col-2" v-if="license.id == 5">
          <img :src="getLogo(14)" class="icon" />
        </div>
        <div class="col-2">
          <p class="tabText">
            {{ license.name }}
          </p>
        </div>
        <div class="col-4">
          <p class="tabText">
            {{ license.description }}
          </p>
        </div>
        <div class="col-1">
          <p class="tabText">${{ license.price }}</p>
        </div>
        <div class="col-2">
          <div class="buttons">
            <b-form-input
              v-model="licensePurchaseCount[license.id - 1]"
              type="number"
              class="cart-field"
              pattern="[0-9]"
              min="0"
            >
            </b-form-input>
          </div>
        </div>
        <div class="col-1">
          <p class="tabText">
            ${{
              parseFloat(
                getSubPrice(licensePurchaseCount[license.id - 1], license.price)
              ).toFixed(2)
            }}
           
          </p>
        </div>
      </div>
      <br />

      <!-- CHEKOUT TOTALS -->
      <div class="checkout-header row">
        <div class="col-2">
          <p class="tabTitle">
            <b-icon icon="cart" font-scale="1"></b-icon> Checkout
          </p>
        </div>
      </div>
      <div class="checkout-row row">
        <div class="col-2">
          <p class="tabText">Subtotal</p>
        </div>
        <div class="col-3">
          <p class="tabText">${{ parseFloat(getPrice()).toFixed(2) }} </p>
        </div>
      </div>
      <div class="checkout-row row">
        <div class="col-2">
          <p class="tabText">Tax (5%)</p>
        </div>
        <div class="col-3">
          <p class="tabText">
            ${{ parseFloat(getPrice() * 0.05).toFixed(2) }}
          </p>
        </div>
      </div>
      <div class="checkout-row row">
        <div class="col-2">
          <p class="tabText">Total</p>
        </div>
        <div class="col-3">
          <p class="tabText">
            ${{ parseFloat(getPrice() + getPrice() * 0.05).toFixed(2) }}
          </p>
        </div>
      </div>
      <!-- END CHEKOUT TOTALS -->

      <b-button
        class="shoppingcart"
        @click="goToCheckout"
        :disabled="checkoutDisabled"
      >
        <b-icon icon="credit-card" font-scale="1"></b-icon> Checkout</b-button
      >
      <br />
      <br />
      <br />
    </div>

    <!-- CHECKOUT MODAL -->
    <b-modal size="xl"  title="Checkout" id="checkout" hide-footer>
      <checkout :order="orderSummary" :orderId="orderId"> </checkout>
    </b-modal>
  </div>
</template>

<script>
import api from "../api/index";
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import checkout from "../components/popup/checkout.vue";

export default {
  name: "Buy",
  components: {
    sidebar,
    upbar,
    checkout
  },
  computed: {
    checkoutDisabled(){
      if(this.licensePurchaseCount.some(x=> x>0) && !this.licensePurchaseCount.some(x=> x%1!=0))
      {
        return false;
      }
      return true;
    }
  },
  data() {
    return {
      LicenseItems: [],
      licensePurchaseCount: [],
      licensePrice: [],
      logged: false,
      message: "",
      count: 0,
      orderSummary: [],
      orderId: 0
    };
  },
  mounted() {
    this.logged = this.$store.getters.isAuthenticated;
  },
  created() {
    this.getLicenseItems();
  },
  methods: {
    makeToast(title, variant, textToShow) {
      this.$bvToast.toast(textToShow, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        solid: false,
      });
    },
    buildOrderSummary() {
      this.orderSummary = [];
      for (let i = 0; i < this.licensePurchaseCount.length; i++) {
        //First, find the Product/License with Index i
        const identifiedProduct = this.LicenseItems.find((x) => x.id === i + 1);
        if (identifiedProduct != undefined) {
          var order = {
            productId: identifiedProduct.id,
            count: this.licensePurchaseCount[i],
            unitPrice: identifiedProduct.price,
          };
          if (order.count > 0) {
            this.orderSummary.push(order);
          }
        }
      }
      console.log(JSON.stringify(this.orderSummary));
    }, 
    computeTotal(){
      var total =0;
      for(let i=0; i<this.orderSummary.length; i++){
        var orderItem = this.orderSummary[i];
        total += orderItem.count * orderItem.unitPrice;
      }
      var tax = total *0.05;
      total += tax;
      total = parseFloat(total).toFixed(2)
      return total;
    },
    goToCheckout() {
      //0- Check our array does not contain decimals (i.e: 0.25)
      if(this.licensePurchaseCount.some(x=> x%1!=0) || this.licensePurchaseCount.some(x=> x<0))
      {
        this.makeToast('Invalid choice', 'danger', 'Please choose a valid quantity.');
        return;
      }
      //1- Build OrderSummary
      this.buildOrderSummary();
      var total = this.computeTotal();
      //2- Save Order
      try
      {
        var orderObject = 
        {
            orderDetails: JSON.stringify(this.orderSummary),
            amount: parseFloat(total),
            state: 1,
            paypalOrderId: '',
            paypalDetails: '',
            error: ''
        };
        api.addOrder(orderObject).then((result) => {
           this.orderId = result.responseData.id;
        });
      }
      catch (error) 
      {
        console.error(error);
      }
      //3- Show Checkout Modal
      this.$bvModal.show("checkout");
    },
    getSubPrice(units, price) {
      return units * price;
    },
    getPrice() {
      let price = 0;
      for (let i = 0; i < this.licensePurchaseCount.length; i++) {
        price += this.licensePurchaseCount[i] * this.licensePrice[i];
      }
      return price;
    },
    getLogo(id) {
      const EndpointImages = {
        0: "./planner.png",
        1: "./trello.png",
        2: "./asana.png",
        3: "./todoist.png",
        4: "./wrike.png",
        5: "./teams.png",
        8: "./sharepoint.png",
        9: "./slack.png",
        11: "./teams-chat.png",
        12: "./clickup.png",
        13: "./groups.png",
        14: "./support.png",
      };
      var images = require.context("../assets/", false, /\.png$/);
      return images(EndpointImages[id]);
    },
    getLicenseItems() {
      api.getLicenseItems().then(
        (response) => {
          this.LicenseItems = response.responseData;
          this.LicenseItems.forEach((item, index) => {
            this.licensePurchaseCount[index] = 0;
            this.licensePrice[index] = item.price;
          });
        },
        (error) => {
          this.makeToast(
            "Oops! Something went wrong",
            "danger",
            error.responseData
          );
        }
      );
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.checkout-header {
  margin-top: 53px;
  width: 50%;
  margin-right: 40px;
  height: 53px;
  border-radius: 30px;
  background: #f2f6ff;
}
.checkout-row {
  margin-right: 40px;
  width: 50%;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.shoppingcart {
  margin-inline: auto;
  margin-top: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 47px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.cart-field {
  margin-inline: auto;
  width: 45%;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #23438e;
  margin-left: 400px;
  margin-right: auto;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 155px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 25px;
  margin-top: 24px;
  width: 11%;
}
.touch {
  cursor: pointer;
}
.plus {
  margin-right: 40px;
  float: right;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.iconPlus {
  margin-right: 20px;
}
</style>
